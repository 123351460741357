<template>
	<CoverageTemplate :page-title="$t('title')" class="mb-4">
		<BRow>
			<BCol>
				<BenefitsBookletDownloadCard
					:description="$t('booklet.description')"
					:download-link-name="$t('booklet.downloadLink')"
					automation-id="myCoverage"
				></BenefitsBookletDownloadCard>
			</BCol>
		</BRow>
		<BRow v-if="!isBenefitsEmpty">
			<BCol>
				<BaseCard>
					<BCardText v-for="(benefitsDescription, index) in benefitsDescriptions" :key="index">
						{{ benefitsDescription }}
					</BCardText>
				</BaseCard>
			</BCol>
		</BRow>
	</CoverageTemplate>
</template>

<script>
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { BRow, BCol, BCardText } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import CoverageTemplate from '@/pages/coverage/CoverageTemplate.vue';
import BenefitsBookletDownloadCard from '@/components/coverage/BenefitsBookletDownloadCard.vue';
import { DASHBOARD, COVERAGE } from '@/constants/Routes.js';
import hspt from '@/models/suppressed-coverages/hspt.js';

export default {
	name: 'HospitalSuppressPage',
	components: {
		BRow,
		BCol,
		BCardText,
		BaseCard,
		CoverageTemplate,
		BenefitsBookletDownloadCard
	},
	mixins: [BreadcrumbsManager],
	data() {
		return {
			benefitsDescriptions: null
		};
	},

	computed: {
		locale() {
			return this.$root.$i18n.locale;
		},
		isBenefitsEmpty() {
			return !this.benefitsDescriptions?.length;
		}
	},

	watch: {
		locale() {
			this.getBenefits();
		}
	},

	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.hspt', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.hspt', 'fr') }
			]
		);
		this.getBenefits();
	},

	methods: {
		async getBenefits() {
			this.benefitsDescriptions = await hspt.getSuppressedBenefitDetails(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale
			);
		}
	}
};
</script>

<i18n>
{
	"en": {
		"title": "Hospital - Benefit Coverage Detail",
    "booklet": {
		"description": "Download your benefits booklet for a full overview of your current plan and all your benefits.",
    "downloadLink": "Download your complete coverage details (PDF)"
    },
		"breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
      "hspt": "Hospital"
		}
	},
	"fr": {
		"title": "Hospitalisation - Renseignements sur la garantie",
    "booklet": {
		"description": "Téléchargez votre brochure de garanties pour obtenir un portrait global de votre régime et de ses garanties.",
    "downloadLink":"Téléchargez les renseignements complets sur votre couverture"
    },
		"breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
      "hspt": "Hôpital"
		}
	}
}
</i18n>

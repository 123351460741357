<template>
	<BCard no-body class="hspt-details">
		<BaseDisplayGroup
			v-if="benefitDetails.description"
			:automation-id="getAutomationId('hspt-description')"
			:label="$t('description')"
		>
			{{ benefitDetails.description }}
		</BaseDisplayGroup>

		<BaseDisplayGroup
			v-if="benefitDetails.provisions"
			:automation-id="getAutomationId('hspt-provisions')"
			:label="$t('provisions')"
		>
			<ul>
				<li v-for="(provision, pKey) in benefitDetails.provisions" :key="pKey">
					{{ provision }}
				</li>
			</ul>
		</BaseDisplayGroup>

		<BaseDisplayGroup
			v-if="benefitDetails.eligibility"
			:automation-id="getAutomationId('hspt-eligibility')"
			:label="$t('eligibility')"
		>
			<ul>
				<li v-for="(eligibility, eKey) in benefitDetails.eligibility" :key="eKey">
					{{ eligibility }}
				</li>
			</ul>
		</BaseDisplayGroup>

		<BaseDisplayGroup
			v-if="benefitDetails.claimRequirements"
			:automation-id="getAutomationId('hspt-requirements')"
			:label="$t('requirements')"
		>
			<ul>
				<li v-for="(requirement, rKey) in benefitDetails.claimRequirements" :key="rKey">
					{{ requirement }}
				</li>
			</ul>
		</BaseDisplayGroup>
	</BCard>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BCard } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';

@Component({
	name: 'HospitalBenefitsCard',
	components: {
		BCard,
		BaseDisplayGroup
	},
	mixins: [IdMixin],
	props: {
		benefitDetails: {
			type: [Object, Array],
			default: () => {}
		}
	}
})
export default class HospitalBenefitsCard extends Vue {}
</script>

<style lang="scss" scoped>
.hospital-details {
	background: transparent;
}
.card {
	border: none;
	margin-bottom: 15px;
}

.display-group {
	margin-bottom: 0;
}
</style>
<i18n>
{
	"en": {
		"description": "Description",
    "provisions": "Provisions",
    "eligibility": "Eligibility",
    "requirements": "Claim Requirement(s)"
	},
	"fr": {
		"description": "Description",
    "provisions": "Dispositions",
    "eligibility": "Admissibilité",
    "requirements": "Critères pour les demandes de règlement"
	}
}
</i18n>

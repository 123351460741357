<template>
	<CoverageTemplate :page-title="title()" class="coverage-padding">
		<BRow v-if="subTitle()">
			<BCol>
				<p>{{ subTitle() }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol md="8">
				<SearchInput
					v-model="searchKey"
					automation-id="hospital"
					@update="resetSearch"
					@click="searchBenefits"
					@keyup.enter="searchBenefits"
				></SearchInput>
			</BCol>
		</BRow>
		<template v-if="isSearchResultsFound">
			<HospitalAccordion
				v-for="(benefitFamily, benefitFamilyIndex) in searchResults"
				:key="benefitFamilyIndex"
				class="parent-accordion mt-3"
			>
				<HospitalAccordionPanel
					:id="`benefitFamily${benefitFamilyIndex}`"
					:ref="`benefitFamily${benefitFamilyIndex}`"
					class="parent-accordion-panel"
					group-name="benefitFamily"
					:has-button="false"
					:visible="true"
					:is-clickable="false"
					:button-label="benefitFamily.title"
					:button-sub-label="benefitFamily.subTitle"
					:automation-id="`benefitFamily${benefitFamilyIndex}`"
				>
					<HospitalAccordion class="child-accordion">
						<HospitalAccordionPanel
							v-for="(benefit, benefitIndex) in benefitFamily.benefits"
							:key="`${benefit.benefitCode}${benefitIndex}`"
							class="child-accordion-panel"
							group-name="benefitDetail"
							:button-label="benefit.shortDesc"
							:automation-id="`benefit${benefitIndex}`"
							@show="getBenefitDetails(benefit.benefitCode)"
						>
							<HospitalBenefitsCard
								:benefit-details="benefitDetails"
								:automation-id="`benefit${benefitIndex}`"
							></HospitalBenefitsCard>
						</HospitalAccordionPanel>
					</HospitalAccordion>
				</HospitalAccordionPanel>
			</HospitalAccordion>
		</template>
		<template v-if="!isSearchResultsFound && !isLoadingData">
			<h2 class="h3 mt-4">{{ $t('notFound.heading') }}</h2>
			<p>{{ $t('notFound.text') }}</p>
		</template>
	</CoverageTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import CoverageTemplate from '@/pages/coverage/CoverageTemplate';
import BaseCard from '@/components/common/card/BaseCard';
import SearchInput from '@/components/common/SearchInput.vue';
import HospitalBenefitsCard from '@/components/coverage/hospital/HospitalBenefitsCard';
import HospitalAccordion from '@/components/coverage/hospital/HospitalAccordion';
import HospitalAccordionPanel from '@/components/coverage/hospital/HospitalAccordionPanel';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import BenefitsBookletDownloadCard from '@/components/coverage/BenefitsBookletDownloadCard';
import { DASHBOARD, COVERAGE } from '@/constants/Routes.js';
import HospitalBenefits from '@/models/coverage/HospitalBenefitsScepter';
import HospitalBenefitDetailsScepter from '@/models/coverage/HospitalBenefitDetailsScepter';

@Component({
	name: 'HospitalBenefitPage',
	mixins: [BreadcrumbsManager],
	data() {
		return {
			searchKey: '',
			searchResults: [],
			isLoadingData: null, // To help determine when to show the search "not found" msg.
			benefitFamilies: [],
			benefitDetails: {},
			benefitCode: null, // Keep the last used ben code. Use when switching lang.
			fuseOptions: {
				isCaseSensitive: false,
				shouldSort: true, // Sort to show the most relevant results on top.
				minMatchCharLength: 3, // Match at least 3 characters.
				location: 0, // Start searching at beginning of string. (default: 0)
				distance: 300, // How far into the string to search.
				threshold: 0.3, // Lowering this requires member to be more precise in their typing. (default: 0.6)
				keys: ['shortDesc', 'benefits.shortDesc'],
				bookletName: ''
			}
		};
	},
	components: {
		BRow,
		BCol,
		BaseCard,
		CoverageTemplate,
		SearchInput,
		HospitalBenefitsCard,
		HospitalAccordion,
		HospitalAccordionPanel,
		BenefitsBookletDownloadCard
	},
	watch: {
		locale: function () {
			// When the locale changes, get the benefit families in the new language.
			this.getBenefits();

			// The member could be changing lang while search results are displayed.  Since the search results are no longer relevant,
			// we clear search results and show all benefits.
			// Also, the member could be changing lang while a benefit detail is visible.  Since the benefits are sorted alphabetically, the
			// order could change and the open details could end up way on the bottom.  This is also avoided by clearing any details
			// closing any open details panels.
			this.resetSearch();
		}
	}
})
export default class HospitalBenefitPage extends Vue {
	get locale() {
		return this.$store.state.i18n.locale ? this.$store.state.i18n.locale : 'en';
	}

	get isSearchResultsFound() {
		return Object.keys(this.searchResults).length > 0;
	}

	title() {
		return this.$t('title');
	}

	subTitle() {
		return this.$t('subTitle');
	}

	get downloadLinkName() {
		return this.bookletName ? this.bookletName : '';
	}

	async mounted() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.hspt', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.hspt', 'fr') }
			]
		);
		await this.getBenefits();
	}

	async getBenefits() {
		this.isLoadingData = true;
		this.benefitFamilies = await HospitalBenefits.getBenefits(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		// Initialized with all data
		this.searchResults = this.benefitFamilies;
		this.isLoadingData = false;
	}

	async getBenefitDetails(benefitCode) {
		this.benefitDetails = {}; // To avoid a flash of old data on the screen.
		this.benefitCode = benefitCode; // Save benefit code.  For switching lang.

		this.benefitDetails = await HospitalBenefitDetailsScepter.getBenefitDetails(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale,
			benefitCode
		);
	}

	async searchBenefits() {
		// We require at least 3 characters for searches
		if (this.searchKey.length < 3) {
			return;
		}

		let searchResults = [];

		// Search only on the second level (benefit descriptions)
		await Promise.all(
			this.benefitFamilies.map(async (element) => {
				const result = await this.$search(this.searchKey, element.benefits, this.fuseOptions);
				if (result.length !== 0) {
					// Results found, add to the search results array.
					searchResults.push({
						title: element.title,
						subTitle: element.subTitle,
						benefits: result
					});
				}
			})
		);

		this.searchResults = searchResults;
	}
	/**
	 * Adding type="search" on the search input gives a visible "x" in the input field when a member
	 * enter text.  A member can click the "x" to clear the search field (it triggers the update event).
	 * We confirm that the field is empty and reset the search results to contain all data.
	 *
	 * Notes:
	 *
	 *  - When using type="search", the "esc" key also clears the input field.  No code needed.
	 *  - Backspace to remove all input field characters will also trigger the reset.
	 *  - When using the search input component, use the event value instead of this.searchKey to
	 *    trigger the reset.  The lifecycle only clears the local data "after" the update event.
	 */
	resetSearch(searchKey) {
		if (!searchKey) {
			this.searchResults = this.benefitFamilies;
			this.benefitDetails = {};
			this.searchKey = '';

			let ctr = 0;

			// Close any open accordions
			while (this.$refs[`benefitFamily${ctr}`]) {
				if (this.$refs[`benefitFamily${ctr}`][0]?.isVisible) {
					this.$root.$emit('bv::toggle::collapse', `benefitFamily${ctr}`);
				}
				ctr++;
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.coverage-padding {
	padding-bottom: 30px;
}

.parent-accordion {
	overflow: hidden;
}

.child-accordion {
	overflow: hidden;
	border-radius: 0;
	border: none;
	margin: 0;

	& ::v-deep .hspt-accordion-btn {
		padding: 15px 15px;
		border-radius: 0;
		border-top: 1px solid $gray;

		&-icon {
			margin: 0 15px 0 0;
		}
	}
}

::v-deep .child-accordion-panel {
	& > .hspt-accordion-content {
		padding: 0 15px;
	}

	& .hspt-accordion-btn-label {
		font-size: 16px;
	}
}

// Adjustments for medium screen sizes and bigger.
@media (min-width: 720px) {
	::v-deep .child-accordion-panel > .hspt-accordion-content {
		padding: 0 30px 5px 42px; // Increase left, right and bottom padding on benefit details panel
	}
}
</style>
<i18n>
{
	"en": {
		"title": "Hospital - Benefit Coverage Detail",
		"subTitle": "The following benefits are subject to any deductible, co-insurance or maximum amount shown in the Overall Provisions and the Benefit Provisions specified below.",
		"breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
      "hspt": "Hospital"
		},
		"notFound": {
      "heading": "No results found",
      "text": "Please try another search."
    }
	},
	"fr": {
		"title": "Hospitalisation - Renseignements sur la garantie",
		"subTitle": "Les couvertures suivantes sont assujetties au montant de la franchise, au pourcentage de remboursement et au montant maximal prévus dans les dispositions générales et les dispositions des garanties indiquées ci-dessous.",
		"breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
      "hspt": "Hôpital"
		},
		"notFound": {
      "heading": "Aucun résultat ne correspond à votre recherche.",
      "text": "Veuillez réessayer."
    }
	}
}
</i18n>

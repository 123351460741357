<script>
// mixins
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// models
import HospitalBenefit from '@/models/coverage/HospitalBenefits.js';
// pages
import CoverageTemplate from '@/pages/coverage/CoverageTemplate.vue';
// components
import CoverageParticipantsSearch from '@/components/coverage/CoverageParticipantsSearch';
// constants
import { DASHBOARD, COVERAGE } from '@/constants/Routes.js';

export default {
	name: 'HospitalPage',
	components: {
		CoverageTemplate,
		CoverageParticipantsSearch
	},
	mixins: [BreadcrumbsManager],
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			// if not back button or breadcrumb click, load page with no data.
			if (from.name !== COVERAGE.HOSPITAL_DETAILS) {
				vm.clearState = true;
			}
		});
	},
	data() {
		return {
			clearState: false,
			model: HospitalBenefit
		};
	},
	computed: {
		detailsRoute() {
			return COVERAGE.HOSPITAL_DETAILS;
		}
	},
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.hospital', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.hospital', 'fr') }
			]
		);
	}
};
</script>

<template>
	<CoverageTemplate
		:page-title="$store.state.loading ? '' : $t('pageTitle')"
		class="mb-4"
		full-width
	>
		<CoverageParticipantsSearch
			page="hospital"
			:model="model"
			:details-route="detailsRoute"
			:clear-state="clearState"
		/>
	</CoverageTemplate>
</template>

<i18n lang="json">
{
	"en": {
		"pageTitle": "Hospital Coverage",
		"breadcrumb": {
			"home": "Home",
			"myCoverage": "Plan Coverage",
			"hospital": "Hospital"
		}
	},
	"fr": {
		"pageTitle": "Couverture d'hospitalisation",
		"breadcrumb": {
			"home": "Accueil",
			"myCoverage": "Couverture du régime",
			"hospital": "Hôpital"
		}
	}
}
</i18n>

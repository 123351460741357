<template>
	<BCard class="hospital-accordion">
		<slot></slot>
	</BCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BCard } from 'bootstrap-vue';

@Component({
	name: 'HospitalAccordion',
	components: {
		BCard
	}
})
export default class HospitalAccordion extends Vue {}
</script>

<style lang="scss" scoped>
.hospital-accordion {
	border: 1px solid $gray;
	background-color: $white;
	margin-bottom: 15px;

	& > ::v-deep .card-body {
		padding: 0;
	}
}
</style>

<!--
  Copied from BaseAccordionPanel to facilitate layout for EHB.
  -->
<template>
	<div :class="{ visible: isAccordionVisible, 'hspt-accordion-panel': hasButton }">
		<BButton
			v-if="hasButton"
			role="tab"
			:aria-controls="id"
			variant="link"
			block
			class="hspt-accordion-btn"
			:automation-id="getAutomationId('accordionButton')"
			@click="changeVisibility(!isAccordionVisible)"
		>
			<div class="d-flex">
				<font-awesome-icon
					v-if="hasButton"
					:icon="['fal', 'angle-down']"
					:flip="iconRotation"
					size="lg"
					class="hspt-accordion-btn-icon"
					aria-hidden="true"
				/>
				<span>
					<span class="hspt-accordion-btn-label">{{ buttonLabel }}</span>
					<p v-if="buttonSubLabel" class="hspt-accordion-btn-sub-label">
						{{ buttonSubLabel }}
					</p>
				</span>
			</div>
		</BButton>
		<div v-if="!hasButton" class="hspt-header">
			<p class="hspt-header-label">{{ buttonLabel }}</p>
			<p v-if="buttonSubLabel" class="hspt-header-sub-label">{{ buttonSubLabel }}</p>
		</div>
		<BCollapse
			:id="id"
			v-bind="$attrs"
			role="tablist"
			class="hspt-accordion-content"
			:accordion="groupName"
			:visible="isAccordionVisible"
			:data-test-automation-id="getAutomationId('accordionContent')"
			:data-tracking-id="getAutomationId('accordionContent')"
			@input="changeVisibility"
			@show="$emit('show')"
		>
			<slot></slot>
		</BCollapse>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id.js';
import { BCollapse, BButton } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'HospitalAccordionPanel',
	components: {
		BCollapse,
		BButton
	},
	inheritedAttrs: false,
	mixins: [IdMixin],
	props: {
		/**
		 * From Bootstrap-vue: The name of the accordion group that this collapse belongs to
		 */
		groupName: {
			type: String,
			required: true
		},

		/**
		 * From Bootstrap-vue: When 'true', expands the collapse
		 */
		visible: {
			type: Boolean,
			default: null
		},
		/**
		 * allows the button to be clicked in case of Hospital the top layer is always open
		 */
		isClickable: {
			type: Boolean,
			default: true
		},
		/**
		 * From Bootstrap-vue: 	Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
		 */
		id: {
			type: String,
			default: ''
		},
		hasButton: {
			type: Boolean,
			default: true
		},
		buttonLabel: {
			type: String,
			default: ''
		},
		buttonSubLabel: {
			type: String,
			default: ''
		}
	}
})
export default class HospitalAccordionPanel extends Vue {
	isVisible = false;

	get isAccordionVisible() {
		return this.visible !== null ? this.visible : this.isVisible;
	}

	// When visible, flip the icon up
	get iconRotation() {
		return this.isAccordionVisible ? 'vertical' : null;
	}

	changeVisibility(visible) {
		if (this.isClickable) {
			if (this.visible !== null) {
				this.$emit('input', visible);
			} else {
				this.isVisible = visible;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.hspt-header {
	text-align: left;
	box-shadow: none;
	text-decoration: none;
	margin: 0;
	padding: 25px 30px;
	@media (max-width: 768px) {
		padding: 25px 15px;
	}

	&-label {
		font-size: 21px;
		font-family: $font-family-headline;
		color: $black;
		font-weight: 400;
		letter-spacing: -0.06px;
		word-break: break-word;
		margin-bottom: 0;
	}

	&-sub-label {
		font-family: $font-family-regular;
		color: $black;
		font-size: 17px;
		line-height: 24px;
		font-weight: 300;
		margin-bottom: 5px;
	}
}

.hspt-accordion-btn {
	text-align: left;
	box-shadow: none;
	text-decoration: none;
	margin: 0;
	padding: 25px 15px;

	&-icon {
		margin: 4px 15px 0 0;
	}

	&-label {
		font-size: 21px;
		letter-spacing: -0.06px;
		word-break: break-word;
	}

	&-sub-label {
		font-family: $font-family-regular;
		color: $black;
		font-size: 17px;
		line-height: 24px;
		font-weight: 300;
		margin-bottom: 5px;
	}
}

.hspt-accordion-panel:hover {
	cursor: pointer;
}

// Change background color on hover.  Only for closed accordion pannels.
.hspt-accordion-panel:hover:not(.visible) {
	background-color: $gray-very-light;

	.hspt-accordion-btn-label {
		text-decoration: underline;
	}
}
</style>
